import App from "next/app";
import React from "react";
import * as Sentry from '@sentry/node'
import { initGA } from '../lib/analytics';
import 'react-image-crop/dist/ReactCrop.css';

const SENTRY_DSN: string | undefined = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (SENTRY_DSN) {
  Sentry.init({dsn: SENTRY_DSN});
}

class MyApp extends App<any> {
  componentDidMount() {
    initGA('UA-150937457-1');
  }
  render() {
    const { Component, pageProps } = this.props;

    // Workaround for https://github.com/zeit/next.js/issues/8592
    const { err } = this.props
    const modifiedPageProps = { ...pageProps, err }

    return <Component {...modifiedPageProps} />
  }
}

export default MyApp;
